import { useState } from 'react';

const useUndoRedo = (initialState) => {
  const [past, setPast] = useState([]);
  const [present, setPresent] = useState(initialState);
  const [future, setFuture] = useState([]);

  const canUndo = past.length > 0;
  const canRedo = future.length > 0;

  const undo = () => {
    if (canUndo) {
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);
      setPast(newPast);
      setFuture([...future, present]);
      setPresent(previous);
    }
  };

  const redo = () => {
    if (canRedo) {
      const next = future[future.length - 1];
      const newFuture = future.slice(0, future.length - 1);
      setFuture(newFuture);
      setPast([...past, present]);
      setPresent(next);
    }
  };

  const setData = (newState) => {
    if (typeof newState === 'function') {
      setPresent(prevPresent => {
        setPast([...past, prevPresent]);
        const result = newState(prevPresent);
        setFuture([]);
        return result;
      });
    } else {
      setPast([...past, present]);
      setPresent(newState);
      setFuture([]);
    }
  };

  const setDataNoUndo = (value) => {
    setPresent(value);
  }

  return { 
    data: present,
    setData,
    setDataNoUndo,
    undo,
    redo,
    canUndo,
    canRedo,
  };
}

export default useUndoRedo;
