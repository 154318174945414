import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from "aws-amplify";
import amplifyconfig from './amplifyconfiguration.json';

import { DataStore } from 'aws-amplify/datastore';
import { Post } from './models';

Amplify.configure(amplifyconfig);

// await DataStore.save(
//   new Post({
//     "title": "Lorem ipsum dolor sit amet",
//     "comments": [],
//     "content": "Lorem ipsum dolor sit amet"
//   })
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
