export const convertSVGToPNG = (svgElement, imageType='png') => {
  if (imageType === 'jpg') {
    imageType = 'jpeg';
  }
  if (['jpeg', 'png'].indexOf(imageType) === -1) {
    throw "Unsupported image type";
  }

  return new Promise((resolve, reject) => {
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.width = 1000;
    img.height = 1000;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const pngDataURL = canvas.toDataURL(`image/${imageType}`);
      resolve(pngDataURL);
    };
    img.onerror = reject;
    img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString);
  });
}
