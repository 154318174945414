import {vec_normalize, vec_perpendicular, vec_add, vec_scale, vec_sub, vec_negate} from '../math';

const Shadow = ({ball, player}) => {
  const ballRadius = 1;
  const playerRadius = 3;
  const maxDistance = 150;
  const getShadow = (ball, player) => {
    const vec = vec_normalize([player.x - ball.x, player.y - ball.y]);
    const perp = vec_perpendicular(vec);
    const a1 = vec_add([ball.x, ball.y], vec_scale(ballRadius, perp));
    const a2 = vec_add([player.x, player.y], vec_scale(playerRadius, perp));
    const b1 = vec_add([ball.x, ball.y], vec_scale(ballRadius, vec_negate(perp)));
    const b2 = vec_add([player.x, player.y], vec_scale(playerRadius, vec_negate(perp)));
    const a3 = vec_add(a1, vec_scale(maxDistance, vec_normalize(vec_sub(a2, a1))));
    const b3 = vec_add(b1, vec_scale(maxDistance, vec_normalize(vec_sub(b2, b1))));
    const pts = [
      a2[0], a2[1],
      a3[0], a3[1],
      b3[0], b3[1],
      b2[0], b2[1]
    ];
    return pts.join(',');
  }
  return (
    <g>
      <polygon points={getShadow(ball, player)} fill="black" />
    </g>
  );
}

export default Shadow;
