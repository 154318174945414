import React, { useState, useRef, useEffect } from 'react';

import Field from '../components/Field';
import Ball from '../components/Ball';
import Player from '../components/Player';
import Shadow from '../components/Shadow';

import { convertSVGToPNG } from '../export';
import { shareImageDataURL, dataURItoBlob, shareImageBlob } from '../share';

import {vec_normalize, vec_perpendicular, vec_add, vec_scale, vec_sub, vec_negate, vec_length, vec_dot} from '../math';
import { pathDataFromStroke, arrowDataFromStroke, isScribble } from '../strokes';

import { SvgProvider } from '../context/useSvgContext';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DownloadIcon from '@mui/icons-material/Download';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import useUndoRedo from '../hooks/useUndoRedo';

import './SoccerBoard.css';

const range = n => [...Array(n).keys()];

const isIpad = /iPad/i.test(navigator.userAgent);

const loadData = () => {
  // 11v11 with home on field
  //return {"view":{"x":0,"y":0,"theta":0,"scale":1},"field":{"width":70,"length":110,"numPlayers":11},"players":[{"team":"away","jersey":10,"x":8.804572105407715,"y":-17.993603706359863},{"team":"away","jersey":2,"x":-25.545955657958984,"y":-25.899279594421387},{"team":"away","jersey":3,"x":27.035972595214844,"y":-27.366107940673828},{"team":"away","jersey":5,"x":6.356512069702148,"y":-37.054359436035156},{"team":"away","jersey":4,"x":-7.464432001113892,"y":-36.83453178405762},{"team":"away","jersey":6,"x":0.9528430700302124,"y":-28.02558135986328},{"team":"away","jersey":8,"x":-9.231015682220459,"y":-21.39887809753418},{"team":"away","jersey":11,"x":29.218225479125977,"y":-4.600321292877197},{"team":"away","jersey":9,"x":0.12150073051452637,"y":-11.538766860961914},{"team":"away","jersey":7,"x":-29.28697395324707,"y":-4.760191440582275},{"team":"away","jersey":1,"x":0.017591692507267,"y":-49.684250831604004},{"team":"home","jersey":9,"x":-0.3599963378906183,"y":3.479985351562499},{"team":"home","jersey":3,"x":-24.839996337890586,"y":31.800007324218754},{"team":"home","jersey":2,"x":24.119992675781244,"y":32.07998168945313},{"team":"home","jersey":6,"x":-3.2399963378906187,"y":26.880003662109395},{"team":"home","jersey":11,"x":-30.240007324218748,"y":3.520000000000005},{"team":"home","jersey":10,"x":-12.239996337890624,"y":13.999999999999998},{"team":"home","jersey":8,"x":6.959996337890638,"y":16.360003662109374},{"team":"home","jersey":7,"x":30.359996337890646,"y":2.600000000000005},{"team":"home","jersey":5,"x":-10.319992675781238,"y":40.84001098632812},{"team":"home","jersey":4,"x":8.400007324218747,"y":41.00000366210936},{"team":"home","jersey":1,"x":-0.11999999999999067,"y":49.760003662109355}],"ball":{"x":0,"y":0},"objects":[],"strokes":[]};
  //return {"view":{"x":0,"y":0,"theta":0,"scale":1},"field":{"width":70,"length":110,"numPlayers":11},"players":[{"team":"home","jersey":9,"x":-0.3599963378906183,"y":3.479985351562499},{"team":"home","jersey":3,"x":-24.839996337890586,"y":31.800007324218754},{"team":"home","jersey":2,"x":24.119992675781244,"y":32.07998168945313},{"team":"home","jersey":6,"x":-3.2399963378906187,"y":26.880003662109395},{"team":"home","jersey":11,"x":-30.240007324218748,"y":3.520000000000005},{"team":"home","jersey":10,"x":-12.239996337890624,"y":13.999999999999998},{"team":"home","jersey":8,"x":6.959996337890638,"y":16.360003662109374},{"team":"home","jersey":7,"x":30.359996337890646,"y":2.600000000000005},{"team":"home","jersey":5,"x":-10.319992675781238,"y":40.84001098632812},{"team":"home","jersey":4,"x":8.400007324218747,"y":41.00000366210936},{"team":"home","jersey":1,"x":-0.11999999999999067,"y":49.760003662109355},{"team":"away","jersey":1,"x":42,"y":-5},{"team":"away","jersey":2,"x":42,"y":-10},{"team":"away","jersey":3,"x":42,"y":-15},{"team":"away","jersey":4,"x":42,"y":-20},{"team":"away","jersey":5,"x":42,"y":-25},{"team":"away","jersey":6,"x":42,"y":-30},{"team":"away","jersey":7,"x":42,"y":-35},{"team":"away","jersey":8,"x":42,"y":-40},{"team":"away","jersey":9,"x":42,"y":-45},{"team":"away","jersey":10,"x":42,"y":-50},{"team":"away","jersey":11,"x":42,"y":-55}],"ball":{"x":0,"y":0},"objects":[], "strokes": []};

  // 11v11 with home and away on field for kickoff..
  //return {"view":{"x":0,"y":0,"theta":0,"scale":1},"field":{"width":70,"length":110,"numPlayers":11},"players":[{"team":"away","jersey":9,"x":0.11999633789062197,"y":-11.63999816894531},{"team":"away","jersey":3,"x":23.280000000000012,"y":-27.72000366210938},{"team":"away","jersey":2,"x":-25.199996337890628,"y":-26.440001831054666},{"team":"away","jersey":11,"x":17.400007324218766,"y":-2.919998168945311},{"team":"away","jersey":7,"x":-16.56000732421874,"y":-2.5999999999999868},{"team":"away","jersey":10,"x":7.319996337890637,"y":-16.51999725341797},{"team":"away","jersey":8,"x":-6.359992675781248,"y":-18.40000000000001},{"team":"away","jersey":6,"x":0.6000000000000006,"y":-28.67999816894531},{"team":"away","jersey":5,"x":7.559992675781254,"y":-36.75999633789062},{"team":"away","jersey":4,"x":-7.800003662109376,"y":-36.2},{"team":"away","jersey":1,"x":-0.00000732421874860556,"y":-51.67999908447266},{"team":"home","jersey":9,"x":-0.3599963378906183,"y":3.479985351562499},{"team":"home","jersey":3,"x":-24.839996337890586,"y":31.800007324218754},{"team":"home","jersey":2,"x":24.119992675781244,"y":32.07998168945313},{"team":"home","jersey":6,"x":-3.2399963378906187,"y":26.880003662109395},{"team":"home","jersey":11,"x":-30.240007324218748,"y":3.520000000000005},{"team":"home","jersey":10,"x":-12.239996337890624,"y":13.999999999999998},{"team":"home","jersey":8,"x":6.959996337890638,"y":16.360003662109374},{"team":"home","jersey":7,"x":30.359996337890646,"y":2.600000000000005},{"team":"home","jersey":5,"x":-10.319992675781238,"y":40.84001098632812},{"team":"home","jersey":4,"x":8.400007324218747,"y":41.00000366210936},{"team":"home","jersey":1,"x":-0.11999999999999067,"y":49.760003662109355}],"ball":{"x":0,"y":0},"objects":[]};

  // default start point below..
  const w = 70;
  const l = 110;
  const numPlayers = 11;

  const playersHome = range(numPlayers).map(i => ({ team: 'home', jersey: i+1, x: w/2 + 7, y: 5 + 5*i }));
  const playersAway = range(numPlayers).map(i => ({ team: 'away', jersey: i+1, x: w/2 + 7, y: -5 + -5*i }));

  return {
    view: {
      x: 0,
      y: 0,
      theta: 0,
      scale: 1,
    },
    field: {
      width: w,
      length: l,
      numPlayers,
    },
    players: playersHome.concat(playersAway),
    ball: { x: 0, y: 0 },
    objects: [],
    strokes: [],
  };
};

// https://www.telerik.com/blogs/how-to-use-svg-react
const SoccerBoard = () => {
  const { data, setData, setDataNoUndo, undo, redo, canUndo, canRedo } = useUndoRedo(loadData());

  //const [data, setData] = useState(initialData);
  const [showShadows, setShowShadows] = useState(false);
  const [drawMode, setDrawMode] = useState(false);
  const [showNumbers, setShowNumbers] = useState(false);
  const svgRef = useRef();
  const svgContainerRef = useRef();

  const [currentStroke, setCurrentStroke] = useState(null);
  const [strokeStyle, setStrokeStyle] = useState(null);

  const [pixelData, setPixelData] = useState({});

  const [lastTeamMoved, setLastTeamMoved] = useState('home');
  const [closestPlayerIndexToBall, setClosestPlayerIndexToBall] = useState(null);

  const updatePixelWidth = () => {
    // todo - can this be calculated straight from the matrix?
    const a = pixel2svg(0, 0);
    const b = pixel2svg(1, 0);
    const length = vec_length(vec_sub([a.x, a.y], [b.x, b.y]));

    const PLAYER_RADIUS = 8.66 / 12 / 3;
    const MIN_PLAYER_RADIUS_PIXELS = 30;
    const minTouchSize = Math.min(MIN_PLAYER_RADIUS_PIXELS * length, PLAYER_RADIUS * 10);

    setPixelData({pixelWidth: length, minTouchSize});
  }
  useEffect(updatePixelWidth, [svgRef]);

  const getId = (team, p) => `${team}_${p.jersey}`;

  const moveState = {};
  const movePlayer = (stage, team, jersey, x, y) => {
    //console.log(stage,team, jersey, x, y);
    setLastTeamMoved(team);
    if (stage === 'down') {
      setData(prev => {
        // move clicked player to first in list so it will be drawn on top
        const index = prev.players.findIndex(e => e.team === team && e.jersey === jersey);
        const newData = {...prev};
        if (team && jersey) {
          newData.players = newData.players.map((x, i) => {
            if (i === 0) {
              return {...(newData.players[index])};
            } else if (i === index) {
              return {...(newData.players[0])};
            } else {
              return x;
            }
          });
        }
        return newData;
      });
      moveState.start = { time: new Date(), x, y};
    } else if (stage === 'move') {
      const a = pixel2svg(x, y);
      const b = pixel2svg(moveState.lastX, moveState.lastY);
      const dx = a.x - b.x;
      const dy = a.y - b.y;

      setDataNoUndo(prev => {
        const newData = {...prev};
        newData.players = newData.players.map((item, i) => {
          if (i===0 && team && jersey) {
            return {
              ...item,
              x: item.x + dx,
              y: item.y + dy
            };
          } else if (jersey === null && item.team === team && item.jersey !== 1 && isOnField(item)) { // don't move goalie
            return {
              ...item,
              x: item.x + dx,
              y: item.y + dy
            };
          } else {
            return item;
          }
        });
        return newData;
      });
    } else if (stage === 'up') {
      const now = new Date();
      const elapsed = now - moveState.start.time;
      console.log('elapsed', elapsed);
      if (elapsed < 100) {
        const index = data.players.findIndex(e => (e.team === team && e.jersey === jersey));
        if (index >= 0) {
          animateBallTo(data.players[index].x, data.players[index].y);
        }
      }
    }
    moveState.lastX = x;
    moveState.lastY = y;
  }

  const getClosestPlayer = (x, y) => {
    const results = (
      data.players
      .map(player => ({ player, distance: vec_length(vec_sub([x, y], [player.x, player.y])) }))
      .sort((a, b) => a.distance - b.distance)
    );
    return results.at(0);
  }
  const moveBall = (stage, x, y) => {
    const newData = {...data};
    if (stage === 'down') {
      const pos = pixel2svg(x, y);
      const closest = getClosestPlayer(data.ball.x, data.ball.y);
      //console.log('closest player distance', closest.distance);
      if (closest.distance < 0.5) {
        data.players.forEach((p, index) => { if (p.team === closest.player.team && p.jersey === closest.player.jersey) {
          setClosestPlayerIndexToBall(index);
        } });
      } else {
        setClosestPlayerIndexToBall(null);
      }
    } else if (stage === 'move') {
      const a = pixel2svg(x, y);
      const b = pixel2svg(moveState.lastX, moveState.lastY);
      const dx = a.x - b.x;
      const dy = a.y - b.y;

      newData.ball.x += dx;
      newData.ball.y += dy;

      if (closestPlayerIndexToBall !== null) {
        newData.players[closestPlayerIndexToBall].x += dx;
        newData.players[closestPlayerIndexToBall].y += dy;
      }

      setData(newData);
    }
    moveState.lastX = x;
    moveState.lastY = y;
  }

  const fps = 20;
  const animateBallTo = (x, y, speed = 30) => {
    console.log('animate to', x, y);
    let vecLast = null;
    let dampen = 1.0;
    const id = setInterval(() => {
      setData(prev => {
        const vec = vec_scale(dampen * speed / fps, vec_normalize(vec_sub([x, y], [prev.ball.x, prev.ball.y])));
        const ballNew = {x: prev.ball.x + vec[0], y: prev.ball.y + vec[1]};

        dampen *= 0.99;
        const dist1 = vec_length(vec_sub([x, y], [ballNew.x, ballNew.y])); // distance to target
        const dist2 = vec_length(vec);  // step distance
        const isDone = (
          (vecLast !== null && vec_dot(vec, vecLast) < 0) ||
          dist1 < dist2
        );
        vecLast = vec;

        if (isDone) {
          clearInterval(id);
          ballNew.x = x;
          ballNew.y = y;
        }

        return {
          ...prev,
          ball: ballNew
        }
      });
    }, 1000 / fps);
  }

  const getTeamColor = p => {
    if (p.jersey === 1) {
      // goalie
      return p.team === 'home' ? 'rgb(200,0,200)' : 'rgb(0,150,150)';
    } else {
      // field player
      return p.team === 'home' ? 'blue' : 'red';
    }
  }

  const isOnField = p => {
    return (
      Math.abs(p.x) <= data.field.width/2 &&
      Math.abs(p.y) <= data.field.length/2
    );
  }

  // const controlsProps = () => {
  //   const transformMatrix = new DOMMatrix(
  //     window.getComputedStyle(document.getElementById('root')).transform
  //   );

  //   const result = {
  //     x: transformMatrix.m41,
  //     y: transformMatrix.m42
  //   };
  //   console.log('result', result);
  //   return result;
  // }
  const toggleShadows = () => {
    setShowShadows(prev => !prev);
  }
  const toggleShowNumbers = () => {
    setShowNumbers(prev => !prev);
  }

  const downloadImage = async () => {
    const dataUrl = await convertSVGToPNG(svgRef.current);

    // https://stackoverflow.com/questions/69918382/how-to-download-dataurl-as-png-on-mobile-using-javascript
    // https://jsfiddle.net/jespertheend/fwmu2apo/10/
    const download = document.createElement('a');
    download.download = 'soccerboard';
    download.href = dataUrl;
    download.target = '_blank'
    download.click();
  }

  const shareAsImage = async () => {
    const dataUrl = await convertSVGToPNG(svgRef.current);
    //shareImageDataURL(dataUrl);
    const blob = dataURItoBlob(dataUrl);
    shareImageBlob(blob, 'myimagefilename.png');
  }

  const pixel2svg = (x, y) => {
    if (!svgRef.current) { return {x:0,y:0} };
    let pt = svgRef.current.createSVGPoint();
    pt.x = x;
    pt.y = y;
    const pixel2svg = svgRef.current.getScreenCTM().inverse();
    pt = pt.matrixTransform(pixel2svg);
    if (isIpad) {
      const tmp = (new DOMMatrix(svgContainerRef.current.style.transform)).inverse();
      pt.x *= tmp.a;
      pt.y *= tmp.a;  
    }
    return pt;
  }
  const svg2pixel = (x, y) => {
    if (!svgRef.current) { return {x:0,y:0} };
    const pt = svgRef.current.createSVGPoint();
    pt.x = x;
    pt.y = y;
    return pt.matrixTransform(svgRef.current.getScreenCTM());
  }

  const last = {};
  const drawDown = e => {
    if (!svgContainerRef.current) return;
    if (!e.isPrimary) return;

    e.preventDefault();
    window.addEventListener('pointermove', drawMove, false);
    window.addEventListener('pointerup', drawUp, false);

    const p = pixel2svg(e.clientX, e.clientY);
    setCurrentStroke([p.x, p.y]);

    last.start = { time: new Date(), point: p };

    // https://www.w3schools.com/graphics/svg_stroking.asp
  }
  const drawMove = e => {
    if (!e.isPrimary) return;
    setCurrentStroke(prev => {
      const value = [...prev];
      const p = pixel2svg(e.clientX, e.clientY);
      if (strokeStyle !== 'Pass') {
        // add next point to line
        value.push(p.x);
        value.push(p.y);
        return value;
      } else {
        // passes are straight lines-- overwrite last point in line
        value[2] = p.x;
        value[3] = p.y;
        return value;
      }
    });
  }
  const drawUp = e => {
    if (!e.isPrimary) return;
    window.removeEventListener('pointermove', drawMove, false);
    window.removeEventListener('pointerup', drawUp, false);

    last.stop = { time: new Date() };

    setCurrentStroke(prev => {
      const keepStroke = prev.length > 3;
      if (keepStroke) {
        const hitIds = isScribble([...prev], data.strokes.map(s => s.points));
        console.log('scribble test', hitIds);
        if (hitIds.length > 0) {
          const newState = {
            ...data,
            strokes: data.strokes.filter((s,index)=>{ return hitIds.indexOf(index) === -1}) // [...data.strokes, stroke]
          };
          setData(newState);
        } else {
          const newStroke = {style: strokeStyle, points: [...prev]};
          addStroke(newStroke);
        }
      }
      return null; // reset currentStroke to null
    });
    setDrawMode(false);
    if (!isIpad) {
      setStrokeStyle(null);
    }
  }
  const choose = (e) => {
    if (drawMode || e.pointerType === 'pen') {
      drawDown(e);
    } else {
      navDown(e);
    }
  }
  const navLast = {}
  const nav = {}
  const navDown = (e) => {
    if (!svgContainerRef.current) return;

    e.preventDefault();
    if (Object.keys(navLast).length === 0) {
      const field_pt = pixel2svg(e.clientX, e.clientY);
      const playerToMove = (
        data.players
        .filter(x => x.team === lastTeamMoved)
        .filter(x => !isOnField(x))
        .at(0)
      );
      nav.start = { time: new Date(), x: field_pt.x, y: field_pt.y};
      nav.team = playerToMove?.team;
      nav.jersey = playerToMove?.jersey;

      window.addEventListener('pointermove', navMove, false);
      window.addEventListener('pointerup', navUp, false);
    }

    const svgDownPt = pixel2svg(e.clientX, e.clientY);
    nav.moveTeam = svgDownPt.x < -data.field.width/2;
    if (nav.moveTeam) {
      nav.moveTeamName = svgDownPt.y > 0 ? 'home' : 'away';
      movePlayer('down', nav.moveTeamName, null, e.clientX, e.clientY);
    }

    navLast[e.pointerId] = {x: e.clientX, y: e.clientY};
  }
  const navMove = (e) => {
    e.preventDefault();

    if (!(e.pointerId in navLast)) {
      // this seems like a bug.. how can a move happen if down didn't first?
      // anyway for now ignore it...
      return;
    }

    const mat = new DOMMatrix(svgContainerRef.current.style.transform);
    switch (Object.keys(navLast).length) {
      case 1: {
        if (nav.moveTeam) {
          movePlayer('move', nav.moveTeamName, null, e.clientX, e.clientY);
          break;
        }

        // handle one-finger navigation
        const imat = mat.inverse();
        const a = new DOMPoint(e.clientX, e.clientY).matrixTransform(imat);
        const b = new DOMPoint(navLast[e.pointerId].x, navLast[e.pointerId].y).matrixTransform(imat);
        const vec = new DOMPoint(a.x - b.x, a.y - b.y);
        mat.translateSelf(vec.x, vec.y);
        // console.log('client', e.clientX, e.clientY);
        // console.log('local', a.x, a.y);
        // console.log('client', navLast[e.pointerId].x, navLast[e.pointerId].y, b.x, b.y);
        // console.log('local', b.x, b.y);
        break;
      }
      case 2: {
        // handle two-finger navigation
        const imat = mat.inverse();

        const navNow = JSON.parse(JSON.stringify(navLast));
        navNow[e.pointerId] = {x: e.clientX, y: e.clientY};

        const ids = Object.keys(navLast).filter(x=>x!='start'&&x!='stop').map(x=>parseInt(x));
        const old = {};
        const cur = {};
        for(const key in ids) {
          const id = ids[key];
          old[id] = new DOMPoint(navLast[id].x, navLast[id].y).matrixTransform(imat);
          cur[id] = new DOMPoint(navNow[id].x, navNow[id].y).matrixTransform(imat);
        }

        const vecOld = vec_sub([old[ids[0]].x, old[ids[0]].y], [old[ids[1]].x, old[ids[1]].y]);
        const vecNew = vec_sub([cur[ids[0]].x, cur[ids[0]].y], [cur[ids[1]].x, cur[ids[1]].y]);
        const lengthOld = vec_length(vecOld);
        const lengthNow = vec_length(vecNew);
        const scale = lengthNow / lengthOld;

        const oldCenter = new DOMPoint();
        const curCenter = new DOMPoint();
        for(const key in ids) {
          const id = ids[key];
          oldCenter.x += 0.5 * old[id].x;
          oldCenter.y += 0.5 * old[id].y;
          curCenter.x += 0.5 * cur[id].x;
          curCenter.y += 0.5 * cur[id].y;
        }

        mat.scale3dSelf(scale, curCenter.x, curCenter.y);
        mat.translateSelf(curCenter.x - oldCenter.x, curCenter.y - oldCenter.y);
        break;
      }
    }
    svgContainerRef.current.style.transform = mat.toString();

    updatePixelWidth();

    navLast[e.pointerId].x = e.clientX;
    navLast[e.pointerId].y = e.clientY;
  }
  const navUp = (e) => {
    e.preventDefault();
    delete navLast[e.pointerId];

    if (Object.keys(navLast).length === 0) {
      window.removeEventListener('pointermove', navMove, false);
      window.removeEventListener('pointerup', navUp, false);

      nav.stop = { time: new Date(), point: {x: e.clientX, y: e.clientY} };

      if (nav.moveTeam) {
        movePlayer('up', nav.moveTeamName, null, e.clientX, e.clientY);
        return;
      }

      const elapsed = nav.stop.time - nav.start.time;
      console.log('elapsed', elapsed);
      if (elapsed < 100) {
        if (nav.team && nav.jersey) {
          // if a player not on the field still exists..
          const newState = {...data};
          newState.players = newState.players.map(item => {
            if (item.team === nav.team && item.jersey === nav.jersey) {
              return {
                ...item,
                x: nav.start.x,
                y: nav.start.y,
              };
            } else {
              return item;
            }
          });
          setData(newState);
        }
      }
    }
  }
  const navWheel = (e) => {
    e.stopPropagation();

    const scale = Math.pow(e.deltaY > 0 ? 0.99 : 1.005, Math.abs(e.deltaY));
    const mat = new DOMMatrix(svgContainerRef.current.style.transform);
    const imat = mat.inverse();
    const pos = new DOMPoint(e.clientX, e.clientY).matrixTransform(imat);
    //const fieldPt = pixel2svg(e.clientX, e.clientY);
    mat.scale3dSelf(scale, pos.x, pos.y);
    svgContainerRef.current.style.transform = mat.toString();
    updatePixelWidth();
  }

  const clearStrokes = () => {
    setData(loadData());
    // setData(prev => ({...prev, strokes: []}));
  }
  const addStroke = (stroke) => {
    //console.log('in addStroke', data.strokes.length);

    const newState = {
      ...data,
      strokes: [...data.strokes, stroke]
    };
    setData(newState);
  }
  const toggleDraw = () => {
    setDrawMode(prev => !prev);
  }

  const polylineDataFromStroke = (pts, style, pixelWidth) => {
    if (style === 'Run' || style === 'Pass') {
      let result = '';
      for(let i=0;i<pts.length;i+=2) {
        result += `${pts[i]}, ${pts[i+1]} `;
      }
      return result;  
    } else if (style === 'Dribble') {
      let result = '';
      for(let i=0;i<pts.length-2;i+=2) {
        const perp = vec_perpendicular(vec_normalize(vec_sub([pts[i+2], pts[i+3]], [pts[i], pts[i+1]])));
        const s = Math.sin(i);
        const p = vec_add([pts[i], pts[i+1]], [s*perp[0], s*perp[1]]);
        result += `${p[0]}, ${p[1]} `;
      }
      return result;
    }
  }

  const createStroke = (stroke, style, pixelWidth) => {
    const strokeColor = isIpad ? "purple" : "purple";
    return (
      <g key={`${stroke[0]}_${stroke[1]}_${stroke.at(-2)}_${stroke.at(-1)}`}>
        <path
          d={pathDataFromStroke(stroke, style, pixelWidth)}
          stroke={strokeColor}
          strokeWidth={pixelWidth * 4}
          strokeDasharray={style === 'Run' ? `${pixelWidth * 10} ${pixelWidth * 10}` : ''}
          fill="transparent"
        />
        <polyline
          className="stroke"
          points={arrowDataFromStroke(stroke, pixelWidth)}
          stroke={strokeColor}
          strokeWidth={pixelWidth * 4}
          fill="transparent"
        />
      </g>
    );
  }

  const undoLastStroke = () => {
    if (data.strokes.length === 0) return;

    setData(prev => {
      const newState = {
        ...prev,
        strokes: [...prev.strokes],
      }
      newState.strokes.pop();
      return newState;
    });
  }

  //console.log(data?.strokes?.length, currentStroke?.length);
  return (
    <>
      <div className="sportboard">
        <div ref={svgContainerRef} className="field-container" onPointerDown={choose} onWheel={navWheel}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            ref={svgRef}
            preserveAspectRatio="xMidYMid meet"
            fill="currentColor"
            viewBox={`${-data.field.width/2 - 10} ${-data.field.length/2 - 10} ${data.field.width + 2*10} ${data.field.length + 2*10}`}
            style={{border: "1px solid black"}}
          >
            <defs>
              <clipPath id="fieldClipPath">
                <rect x={-data.field.width/2} y={-data.field.length/2} width={data.field.width} height={data.field.length} />
              </clipPath>
              <clipPath id="outsidePenaltyAreas">
                <rect x={-data.field.width/2} y={-data.field.length/2+18} width={data.field.width} height={data.field.length-18*2} />
              </clipPath>
            </defs>
            <SvgProvider pixelData={pixelData} pixel2svg={pixel2svg} svg2pixel={svg2pixel}>
              <Field width={data.field.width} length={data.field.length} />
              {showShadows && (
                <g opacity={0.5} clipPath="url(#fieldClipPath)">
                  {data.players.filter(p=>p.team==='away').filter(p=>isOnField(p)).map(p => <Shadow ball={data.ball} player={p} />)}
                </g>
              )}
              {[...data.players].reverse().map(p => (
                <Player
                  key={`${p.team}_${p.jersey}`}
                  team={p.team}
                  jersey={p.jersey}
                  x={p.x}
                  y={p.y}
                  showNumbers={showNumbers}
                  color={getTeamColor(p)}
                  moveFunc={movePlayer}
                />
              ))}
              <Ball x={data.ball.x} y={data.ball.y} moveFunc={moveBall}/>
              {/*
                run = dashed white
                pass = solid red
                dribble = squiggle red
              */}
              {currentStroke && createStroke(currentStroke, strokeStyle, pixelData.pixelWidth)}
              {data && data.strokes.map(s => createStroke(s.points, s.style, pixelData.pixelWidth))}
              {drawMode && (
                <Field width={data.field.width} length={data.field.length} rectOnly={true} fill="rgba(255,255,255,0.01)" />
              )}
            </SvgProvider>
          </svg>
        </div>
      </div>

      <div className="gui">
        <div className="gui-buttons">
          <button onClick={clearStrokes}>Clear</button>
          <button onClick={toggleShadows}>Shadows</button>
          {/* <button onClick={downloadImage}><span className="download-icon"><DownloadIcon /></span> PNG</button> */}
          <button onClick={shareAsImage}>Share PNG</button>
          <button onClick={toggleShowNumbers}>Numbers</button>
        </div>
        <div className="gui-buttons">
          {/* <button onClick={toggleDraw}>{drawMode ? 'Move' : 'Draw'}</button> */}

          <div style={{background: 'rgba(255,255,255,0.9)', display: 'inline-block'}}>
            <ToggleButtonGroup
              value={strokeStyle}
              exclusive
              onChange={(e, newValue) => { setDrawMode(true); setStrokeStyle(newValue || strokeStyle); }}
              aria-label="Draw style"
            >
              <ToggleButton value="Run">Run</ToggleButton>
              <ToggleButton value="Pass">Pass</ToggleButton>
              <ToggleButton value="Dribble">Dribble</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="gui-buttons">
          <button onClick={undoLastStroke} disabled={data.strokes.length === 0}>Undo Stroke</button>
          <button onClick={undo} disabled={!canUndo}>Undo</button>
          <button onClick={redo} disabled={!canRedo}>Redo</button>
        </div>
      </div>
    </>
  );
}

export default SoccerBoard;
