export const shareImageDataURL = (dataURL) => {
  if (navigator.share) {
    navigator.share({
      title: 'Share image title',
      text: 'Share image text',
      url: dataURL
    })
    .then(() => console.log('Image shared successfully.'))
    .catch(err => console.error('Error sharing image:', err));
  } else {
    // Fallback for browsers that don't support navigator.share
    prompt('Copy image URL:', dataURL);
  }
}

// https://stackoverflow.com/questions/12168909/blob-from-dataurl
export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;
}

export const shareImageBlobOriginal = async (blob) => {
  if (navigator.canShare && navigator.canShare({ files: [blob] })) {
    try {
      await navigator.share({
        files: [blob],
        title: 'Shared Image', // Optional title
        text: 'Check out this image!', // Optional description
      });
    } catch (error) {
      console.error('Error sharing image:', error);
    }
  } else {
    console.log('Sharing not supported on this browser.');
  }
};

export const shareImageBlob = async (blob, fileName) => {
  const file = new File([blob], fileName, { type: blob.type });

  if (navigator.canShare && navigator.canShare({ files: [file] })) {
    try {
      await navigator.share({ files: [file] });
    } catch (error) {
      console.error('Error sharing the file:', error);
    }
  } else {
    console.log('Sharing files is not supported on this browser.');
  }
}