import SoccerBoard from './containers/SoccerBoard';
import { useEffect } from 'react';

import './App.css';

function App() {
  const handleLeave = (e) => {
    e.preventDefault(); // Prevents the default behavior (immediate exit)
    e.returnValue = ''; // Required for some browsers (Chrome, Safari)
    return 'Are you sure you want to leave?'; // Message displayed in the prompt
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleLeave);
    return () => {
      window.removeEventListener('beforeunload', handleLeave);
    }
  }, []);

  return (
    <div className="App">
      <SoccerBoard />
    </div>
  );
}

export default App;
