import { createContext, useContext } from 'react'

const SvgContext = createContext();

export const SvgProvider = ({pixelData, pixel2svg, svg2pixel, children}) => {
  return (
    <SvgContext.Provider value={({pixelData, pixel2svg, svg2pixel})}>
      {children}
    </SvgContext.Provider>
  );
}

export const useSvgContext = () => {
  return useContext(SvgContext);
}
