const Field = ({width, length, rectOnly=false, fill="green"}) => {
  const LINE_WIDTH = 4 / 12 / 3;
  const p = 4; // padding

  const w = width;
  const l = length;

  // goal width & height = 24 x 8
  /*
    Penalty spot: 12 yards from the goal line
    Goal area length: 6 yards
    Penalty area length: 18 yards
    Centre circle radius: 10 yards
    Corner quadrants: 1 yard
  */

    // https://snapsoccer.com/helpful-tips/soccer-pitch-sizes-and-field-dimensions/
  return (
    <g>
      <rect
        x={-w/2 - p}
        y={-l/2 - p}
        width={w + 2*p}
        height={l + 2*p}
        fill={fill}
      />

      {!rectOnly && (
        <>
          {/* lines */}
          <rect
            x={-w/2}
            y={-l/2}
            width={w}
            height={l}
            stroke="white"
            strokeWidth={LINE_WIDTH}
            fill="transparent"
          />

          {/* center circle */}
          <line x1={-w/2} y1={0} x2={w/2} y2={0} stroke="white" strokeWidth={LINE_WIDTH} />
          <circle cx={0} cy={0} r={10} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
          <line x1={0} y1={-.66} x2={0} y2={.66} stroke="white" strokeWidth={LINE_WIDTH} />

          {/* goals */}
          <polyline points={`${-24/3/2},${l/2} ${-24/3/2},${l/2 + 4/3} ${24/3/2},${l/2 + 4/3} ${24/3/2},${l/2}`} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
          <polyline points={`${-24/3/2},${-l/2} ${-24/3/2},${-l/2 - 4/3} ${24/3/2},${-l/2 - 4/3} ${24/3/2},${-l/2}`} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />

          {/* penalty areas & goals */}
          <rect x={-22} y={-l/2} width={44} height={18} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
          <rect x={-10} y={-l/2} width={20} height={6} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
          <rect x={-22} y={l/2-18} width={44} height={18} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
          <rect x={-10} y={l/2-6} width={20} height={6} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
          <circle cx={0} cy={-l/2 + 13} r={0.33} stroke="white" strokeWidth={LINE_WIDTH} fill="white" />
          <circle cx={0} cy={l/2 - 13} r={0.33} stroke="white" strokeWidth={LINE_WIDTH} fill="white" />
          {/* <path d={`M 0, ${-l/2} A 10 10 0 0 1 10, ${-l/2}`} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" /> */}

          {/* arcs */}
          <g clipPath="url(#outsidePenaltyAreas)">
            <circle cx={0} cy={-l/2 + 13} r={10} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
            <circle cx={0} cy={ l/2 - 13} r={10} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
          </g>
          <g clipPath="url(#fieldClipPath)">
            <circle cx={-w/2} cy={-l/2} r={1} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
            <circle cx={ w/2} cy={-l/2} r={1} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
            <circle cx={ w/2} cy={ l/2} r={1} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
            <circle cx={-w/2} cy={ l/2} r={1} stroke="white" strokeWidth={LINE_WIDTH} fill="transparent" />
            <line x1={-w/2 + 10} y1={-l/2} x2={-w/2 + 10} y2={-l/2 + 1} stroke="white" strokeWidth={LINE_WIDTH} />
            <line x1={ w/2 - 10} y1={-l/2} x2={ w/2 - 10} y2={-l/2 + 1} stroke="white" strokeWidth={LINE_WIDTH} />
            <line x1={-w/2 + 10} y1={ l/2} x2={-w/2 + 10} y2={ l/2 - 1} stroke="white" strokeWidth={LINE_WIDTH} />
            <line x1={ w/2 - 10} y1={ l/2} x2={ w/2 - 10} y2={ l/2 - 1} stroke="white" strokeWidth={LINE_WIDTH} />

            <line x1={-w/2} y1={-l/2+10} x2={-w/2 + 1} y2={-l/2+10} stroke="white" strokeWidth={LINE_WIDTH} />
            <line x1={ w/2} y1={-l/2+10} x2={ w/2 - 1} y2={-l/2+10} stroke="white" strokeWidth={LINE_WIDTH} />
            <line x1={-w/2} y1={ l/2-10} x2={-w/2 + 1} y2={ l/2-10} stroke="white" strokeWidth={LINE_WIDTH} />
            <line x1={ w/2} y1={ l/2-10} x2={ w/2 - 1} y2={ l/2-10} stroke="white" strokeWidth={LINE_WIDTH} />
          </g>
        </>
      )}
    </g>
  );
}

export default Field;
