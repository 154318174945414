import React, { useEffect, useRef } from 'react';
import { useSvgContext } from '../context/useSvgContext';

import {vec_sub, vec_length} from '../math';

import './Player.css';

const PLAYER_RADIUS = 4 * 8.66 / 12 / 3;  // later, use this in some way

const Player = ({team, jersey, x, y, showNumbers, color, moveFunc}) => {
  const {pixelData, pixel2svg, svg2pixel} = useSvgContext();
  const {pixelWidth, minTouchSize} = pixelData;

  const r = minTouchSize;
  const fontSize = 0.9 * r;

  // https://stackoverflow.com/questions/45678190/dynamically-disabling-touch-action-overscroll-for-svg-elements
  // https://stackoverflow.com/questions/49541173/how-to-prevent-default-handling-of-touch-events
  const touchStart = (e) => {
    // prevent touch-action 'auto' behavior
    e.preventDefault();
    e.stopPropagation();
  }
  const down = (e) => {
    if (e.pointerType === 'pen') return;  // pen always draws
    if (!e.isPrimary) return;
    e.stopPropagation();
    window.addEventListener('pointermove', move, false);
    window.addEventListener('pointerup', up, false);
    moveFunc('down', team, jersey, e.clientX, e.clientY);
  }
  const move = (e) => {
    if (!e.isPrimary) return;
    moveFunc('move', team, jersey, e.clientX, e.clientY);
  }
  const up = (e) => {
    if (!e.isPrimary) return;
    window.removeEventListener('pointermove', move, false);
    window.removeEventListener('pointerup', up, false);
    moveFunc('up', team, jersey, e.clientX, e.clientY);
  }

  // disable pan-zoom if touch is on player element
  const gRef = useRef();
  useEffect(() => {
    if (!gRef) return;
    gRef.current.addEventListener('touchstart', touchStart, {passive: false});
    return () => {
      gRef.current.removeEventListener('touchstart', touchStart, {passive: false});
    };
  });

  return (
    <g ref={gRef} className="player" onPointerDown={down} transform={`translate(${x}, ${y})`}>
      <circle cx={0} cy={0} r={r} fill={color} stroke="black" strokeWidth={0.1*r}/>
      {showNumbers && (
        <text x={0} y={0.3*r} textAnchor="middle" fontSize={fontSize} fill="white">{jersey}</text>
      )}
    </g>
  );
}

export default Player;
