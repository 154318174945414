export const vec_normalize = (v) => {
  const l = Math.sqrt(v[0]*v[0] + v[1]*v[1]);
  return [v[0] / l, v[1] / l];
}

export const vec_perpendicular = (v) => {
  return [-v[1], v[0]];
}

export const vec_add = (a, b) => {
  return [a[0] + b[0], a[1] + b[1]];
}

export const vec_sub = (a, b) => {
  return [a[0] - b[0], a[1] - b[1]];
}

export const vec_scale = (s, v) => {
  return [s*v[0], s*v[1]];
}

export const vec_negate = (v) => {
  return [-v[0], -v[1]];
}

export const vec_length = (v) => {
  return Math.sqrt(v[0]*v[0] + v[1]*v[1]);
}

export const vec_length_squared = (v) => {
  return v[0]*v[0] + v[1]*v[1];
}

export const vec_dot = (a, b) => {
  return a[0] * b[0] + a[1] * b[1];
}
